<template>
  <div class="Know">
    <page-top/>
    <clock/>
    <div class="ballTip"></div>
    <div class="ballNum">{{knowledgePointNum}}个艺术知识点</div>
    <div class="balls" ref="ballsEl" id="container">

    </div>
  </div>
</template>

<script>
import PageTop from "../components/PageTop";
import Clock from "../components/Clock";
import {nextTick, onMounted, onUnmounted, reactive, ref, toRefs} from "vue";
import {BallBox, Ball} from '../assets/js/colls_diy'
import service from "../utils/axios";
import {changePage} from "../utils/getPageTime";
import {useRouter} from "vue-router";
export default {
  name: "Know",
  components: {Clock, PageTop},
  setup(){

    let router = useRouter();

    function rand(m, n) {
      return m + parseInt(String((n - m) * Math.random()));
    }

    function initBall(oC, datalist) {
      let mxidth = oC?.offsetWidth, mxheight = oC?.offsetHeight;
      let oB = new BallBox('container')
      oB.ballRun();

      for (let i = 0; i < datalist.length; i++) {
        let data = datalist[i];
        let imgUrl = require('../assets/images/ic_qiu_l@2x.png');
        let text = data.tipText;
        let fontSize, padding, color;
        let radius = rand(40, 60);
        if (data.popType === 1) {
          imgUrl = require('../assets/images/ic_qiu_s@2x.png');
          radius = window.screen.width === 1280? 42:69;
          fontSize = window.screen.width === 1280? 12: 18;
          // padding = window.screen.width === 1280? '20px 18px':'30px 25px'
          color = '#712D05'
        } else if (data.popType === 2) {
          imgUrl = require('../assets/images/ic_qiu_m@2x.png');
          radius = window.screen.width === 1280? 55: 82;
          fontSize = window.screen.width === 1280? 15:22
          // padding = window.screen.width === 1280? '16px 25px':'40px 25px'
          color = '#140065'
        } else if (data.popType === 3) {
          imgUrl = require('../assets/images/ic_qiu_l@2x.png');
          radius = window.screen.width === 1280? 65: 96;
          fontSize = window.screen.width === 1280? 17: 26
          // padding = window.screen.width === 1280?"35px 20px":'50px 30px'
          color = '#00406E'
        }

        let x = rand(radius, mxidth - radius);
        let y = rand(radius, mxheight - radius);
        let ball = new Ball({
          b: radius,
          x,
          y,
          sx: rand(0.8, 1),
          sy: rand(0.8, 1),
          html: text,
          c: `url('${imgUrl}')`,
          opa: 0.9,
          padding: 0,
          callBack: function (n) {
            this.setM(1)
          },
          fontSize: fontSize,
          fontColor: color
        })

        oB.addBall(ball)
      }

    }

    const getData = async () => {
      const data = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/knowledge')
      let ballList = []
      data.largeKnowledgePoint && data.largeKnowledgePoint.forEach(v => {
        ballList.push({popType: 3, tipText: v})
      })
      data.mediumKnowledgePoint && data.mediumKnowledgePoint.forEach(v => {
        ballList.push({popType: 2, tipText: v})
      })
      data.trumpetKnowledgePoint && data.trumpetKnowledgePoint.forEach(v => {
        ballList.push({popType: 1, tipText: v})
      })

      if (ballList.length > 0 && ballsEl.value) {
        initBall(ballsEl.value, ballList)
      }

      state.knowledgePointNum = data.knowledgePointNum;


      state.timeout = await changePage(router,'知识图谱')
    }

    let state = reactive({
      knowledgePointNum: 0,
      timeout: null,
    })

    let ballsEl = ref(null)

    onMounted(()=>{
      getData();
    })

    onUnmounted(()=>{
      state.timeout && clearTimeout(state.timeout);
    })



    return {
      ...toRefs(state),
      ballsEl,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.Know {
  @include pageMix;
  .ballTip{
    width: 399px;
    height: 143px;
    @include backgroundMix;
    background-image: url("../assets/images/balltip.png");
    position: absolute;
    top: 103px;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width:  1280px){
      width: 266px;
      height: 95px;
      top: 69px;
    }
  }
  .ballNum{
    width: 220px;
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    color: #FFFFFF;
    position: absolute;
    bottom: 71px;
    left: 833px;
    @media(max-width: 1280px){
      font-size: 16px;
      bottom: 47px;
      left: 555px;
    }
  }
  .balls{
    width: 100%;
    height: calc(100% - 260px);
    overflow: hidden;
    position: absolute;
    top: 260px;
    @media (max-width: 1280px) {
      height: calc(100% - 200px);
      top: 200px;
    }
  }
}
</style>
