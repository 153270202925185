function Ball(a) {
  var a = this.json = a || {};
  var d = this.type = a.type || 'ball';
  var b = this.b = a.b || 30;
  var f = this.borderWidth = a.borderWidth || 0;
  var g = this.borderColor = a.borderColor || '#000';
  var x = this.x = a.x || b;
  var y = this.y = a.y || b;
  var h = this.sx = a.sx || 3;
  var i = this.sy = a.sy || 3;
  var m = this.m = a.m || (b / 30);
  var c = this.c = a.c || 'pink';
  var e = this.e = a.e || document.createElement('div');
  var j = this.html = a.html;
  var k = this.opa = a.opa || 1;
  var data = {}
  var l = this.fontSize = a.fontSize || 12;
  var p = this.fontColor = a.fontColor || '#FFF';
  var q = this.padding = a.padding || '5px';
  var n = this.callBack = a.callBack ||
    function () {
    };
  this.collNum = 0;
  this.initStyle();
  this.addEvent()
}

function BallBox(a, b) {
  var c = this.container = document.getElementById(a);
  var b = this.opts = b || {};
  this.width = b.width || c.offsetWidth;
  this.height = b.height || c.offsetHeight;
  this.child = []
}



BallBox.prototype.addBall = function (a) {
  this.child.push(a);
  if (a.e.parentNode != this.container) {
    this.container.appendChild(a.e)
  }
};
BallBox.prototype.ballRun = function () {
  clearInterval(this.ballRunTimer);
  var g = this;
  var w = this.width;
  var h = this.height;
  var k = this.isColl;
  var l = this.coll;
  this.ballRunTimer = setInterval(function () {
    var a = g.child;
    var c = a.length;
    for (var i = 0; i < c; i++) {
      a[i].foot()
    }
    for (var i = 0; i < c; i++) {
      var d = a[i];
      var b = d.b;
      if (d.x < b) {
        d.x = b;
        d.sx *= -1
      }
      if (d.y < b) {
        d.y = b;
        d.sy *= -1
      }
      var e = w - b;
      if (d.x > e) {
        d.x = e;
        d.sx *= -1
      }
      var f = h - b;
      if (d.y > f) {
        d.y = f;
        d.sy *= -1
      }
    }
    for (var i = 0; i < c; i++) {
      for (var j = i + 1; j < c; j++) {
        if (k(a[i], a[j])) {
          l(a[i], a[j]);
          a[i].collNum++;
          a[i].callBack(a[i].collNum);
          a[j].collNum++;
          a[j].callBack(a[j].collNum)
        }
      }
    }
  }, 30)
};
BallBox.prototype.ballStop = function () {
  clearInterval(this.ballRunTimer)
};
BallBox.prototype.coll = function (a, b) {
  var c = b.x - a.x;
  var d = b.y - a.y;
  var e = b.sx - a.sx;
  var f = b.sy - a.sy;
  var g = Math.atan2(f, e);
  var h = Math.atan2(-d, -c);
  var i = Math.abs(h - g);
  var D = Math.PI / 2;
  if (i <= 3 * D && i >= D) return;
  var j = a.m || 1;
  var k = b.m || 1;
  var l = j + k;
  var m = ((j - k) * a.sx + 2 * k * b.sx) / l;
  var n = ((k - j) * b.sx + 2 * j * a.sx) / l;
  var o = ((j - k) * a.sy + 2 * k * b.sy) / l;
  var p = ((k - j) * b.sy + 2 * j * a.sy) / l;
  a.sx = m;
  a.sy = o;
  b.sx = n;
  b.sy = p
};
BallBox.prototype.isColl = function (a, b) {
  if (a.stopfoot || b.stopfoot) return false;
  var d = Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);
  var c = Math.pow(a.b + b.b, 2);
  return d < c
};




Ball.prototype.initStyle = function () {
  var b = this.b;
  var s = this.e.style;
  s.position = 'absolute';
  s.top = s.left = 0;
  s.width = s.height = (b - this.borderWidth) * 2 + 'px';
  s.borderRadius = '50%';
  s.background = this.c;
  s.backgroundSize = 'cover';
  s.border = this.borderWidth + 'px solid ' + this.borderColor;
  s.opacity = this.opa;
  if (this.html) {
    var span = document.createElement("span");
    span.style.width = s.width;
    span.style.maxHeight = s.width;
    span.style.textAlign = 'center';
    span.innerHTML = this.html;
    span.className = 'ball-esc'
    this.e.appendChild(span);
    // this.e.innerHTML = this.html;
    s.display = 'flex';
    s.alignItems = 'center';
    s.justifyContent = 'center';
    s.textAlign = 'center';
    // s.lineHeight = (b - this.borderWidth) * 2 + 'px';
    s.fontSize = this.fontSize + 'px'
    s.color = this.fontColor;
    s.overflow = 'hidden'
    s.padding = this.padding
  }
  this.setPos()
};
Ball.prototype.addEvent = function () {
  var a = this;
  this.e.onmouseenter = function () {
    a.stopFoot()
  };
  this.e.onmouseout = function () {
    a.startFoot()
  }
};
Ball.prototype.foot = function (n) {
  if (this.stopfoot) return;
  var n = n || 1;
  this.x += this.sx * n;
  this.y += this.sy * n;
  this.setPos()
};
Ball.prototype.startFoot = function () {
  this.stopfoot = false;
  this.e.style.zIndex = 1
};
Ball.prototype.stopFoot = function () {
  this.stopfoot = true;
  this.e.style.zIndex = 999
};
Ball.prototype.setPos = function () {
  var b = this.b;
  var x = parseInt(this.x - b);
  var y = parseInt(this.y - b);
  this.e.style.transform = 'translate(' + x + 'px,' + y + 'px)';
  this.e.style.webkitTransform = 'translate(' + x + 'px,' + y + 'px)'
};
Ball.prototype.setB = function (n) {
  var b = this.b = n;
  var s = this.e.style;
  s.width = s.height = (b - this.borderWidth) * 2 + 'px';
  s.lineHeight = (b - this.borderWidth) * 2 + 'px';
  this.setPos()
};
Ball.prototype.setC = function (a) {
  var c = this.c = a;
  this.e.style.background = c;
  this.e.style.backgroundSize = 'cover'
};
Ball.prototype.setBorderWidth = function (n) {
  var n = this.borderWidth = n;
  var s = this.e.style;
  s.width = s.height = (this.b - n) * 2 + 'px';
  s.lineHeight = (this.b - n) * 2 + 'px';
  s.border = n + 'px solid ' + this.borderColor
};
Ball.prototype.setBorderColor = function (a) {
  var a = this.borderColor = a;
  this.e.style.border = this.borderWidth + 'px solid ' + a
};
Ball.prototype.setM = function (n) {
  this.m = n || this.b / 30
};
Ball.prototype.setHTML = function (a) {
  var b = this.html = a;
  var s = this.e.style;
  this.e.innerHTML = b;
  s.textAlign = 'center';
  s.verticalAlign = 'middle';
  s.lineHeight = 'normal';
  s.display = 'table-cell';
  // s.lineHeight = (this.b - this.borderWidth) * 2 + 'px';
  s.fontSize = this.fontSize + 'px'
};
Ball.prototype.setOpa = function (n) {
  var a = this.opa = n;
  this.e.style.opacity = n
};
Ball.prototype.setX = function (n) {
  this.x = n;
}
Ball.prototype.setY = function (n) {
  this.y = n;
}

export {
  Ball,
  BallBox
}
